const languages = require('../languages.json');

async function checkInstalledApp() {
  const relatedApps = await navigator.getInstalledRelatedApps();
  if( relatedApps.length == 0 ) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.lintasin.monitor';
  } else {
    window.location.href = `lintasinapp://monitor${window.location.pathname}`;
    window.close();
  }
}

function localization() {
  const current = navigator.language;
  return languages[current] ?? languages['en'];
}

export {
  checkInstalledApp,
  localization,
}