import React from 'react';
import logo from './images/lintasin_monitoring.png';
import guide from './images/continue_en.png';
import './App.css';
import { isBrowser } from 'react-device-detect';
import { checkInstalledApp, localization } from './utils';

function App() {

  if(isBrowser) {
    window.location.href = 'https://lintasin.app';
  } else {
    checkInstalledApp();
  }

  const lang = localization();
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Lintasin monitor logo" />
        <p>{lang.text1}</p>
        <p><b>{lang.or}</b></p>
        <p>{lang.text2}</p>
      </header>
    </div>
  );
}

export default App;
